* {
  background: #060b1a;
  font-family: 'Roboto', sans-serif;
  justify-content: center;
}

.App {
  text-align: center;
  height: 98vh;
  padding-top: 25px;
  padding-bottom: 50px;
}

.bild {
  width: auto;
  height: auto;
  position: relative;
  z-index: 1;
}


.bild a {
  text-decoration: none;
}

.bild img {
  height: 250px;
  width: 250px;
}

.bild h1 {
  color: white;
}

.hero__btns {
  display: inline-block;
  align-items: center;
  flex-direction: column;
  padding-left: 10px;
}

.primary__btn {
  padding: 0.8rem 1.5rem;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 0.2rem;
  cursor: pointer;
  margin-top: 15px;
  background: #090238;
  border: 1px solid #379cf6;
}

.buttons {
  padding-top: 25px;
  color: #379cf6;
}

.buttons1 {
  padding-top: 25px;
  color: #379cf6;
  padding-bottom: 50px;
}